import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Button, Table, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PageSection from '@/components/_common/PageSection';
import PrivatePageHeader from '@/components/_common/PrivatePageHeader';
import CreateUserModal from '@/components/Users/CreateUserModal';

const UsersPage = ({ route }) => {
  const firestore = useFirestore();

  useFirestoreConnect({
    collection: `users`,
    storeAs: 'users',
  });

  const { users = {} } = useSelector((state) => state.firestore.data);

  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);

  const userDataSource = Object.keys(users)
    .map((key) => {
      if (users[key])
        return {
          ...users[key],
          id: key,
        };
      return null;
    })
    .filter((i) => i);

  const handleCreateUserModalVisible = () => {
    setCreateUserModalVisible(!createUserModalVisible);
  };

  const handleRemoveClick = (user) => {
    Modal.confirm({
      title: 'Remoção de Usuário',
      icon: <ExclamationCircleOutlined />,
      content: `Deseja realmente remover o usuário ${user.name}?`,
      okType: 'danger',
      async onOk() {
        try {
          await firestore
            .collection('users')
            .doc(user.id)
            .delete();

          message.success('Usuário removido com sucesso!');
        } catch (err) {
          message.error('Falha ao remover o usuário.');
        }
      },
    });
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
    },
    {
      title: 'Perfil',
      render: (_, row) => (row.role === 'admin' ? 'Administrador' : 'Usuário'),
    },
    {
      title: 'Ações',
      render: (_, row) => (
        <div>
          <Button danger onClick={() => handleRemoveClick(row)}>
            Remover
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PrivatePageHeader
        route={route}
        title="Usuários"
        onBack={() => window.history.back()}
        extra={[
          <Button
            key="new"
            type="primary"
            onClick={handleCreateUserModalVisible}
          >
            Novo Usuário
          </Button>,
        ]}
      />
      <PageSection className="table-page-section">
        <Table
          size="middle"
          rowKey={(item) => item.id}
          columns={columns}
          dataSource={userDataSource}
        />
      </PageSection>
      <CreateUserModal
        visible={createUserModalVisible}
        handleVisible={handleCreateUserModalVisible}
      />
    </>
  );
};

UsersPage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default UsersPage;
