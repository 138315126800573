import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

import './style.less';

const PageSection = ({
  className,
  skeletonProps,
  skeletonClassName,
  loading,
  children,
  actions,
  ...rest
}) => (
  <div className="page-section-wrapper" {...rest}>
    {actions ? <div className="section-actions">{actions}</div> : null}
    <Skeleton
      active
      loading={loading}
      className={skeletonClassName || 'default-skeleton'}
      {...skeletonProps}
    >
      <div className={`${className ? ` ${className}` : 'default'}`}>
        {children}
      </div>
    </Skeleton>
  </div>
);

PageSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
  actions: PropTypes.array,

  className: PropTypes.string,
  loading: PropTypes.bool,

  skeletonClassName: PropTypes.string,
  skeletonProps: PropTypes.object,
};

PageSection.defaultProps = {
  loading: false,
};

export default PageSection;
