import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Button, message, Modal, Table, Tag, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { downloadJson } from '@/utils/firebase';
import PageSection from '@/components/_common/PageSection';
import UpdateCompanyServicesModal from '@/components/Companies/UpdateCompanyServicesModal';
import CreateCompanyUserModal from '@/components/Companies/CreateCompanyUserModal';

import 'firebase/storage';

const CompanyDetailsConfigPage = () => {
  const { companyId } = useRouteMatch().params;
  const firebase = useFirebase();
  const firestore = useFirestore();

  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);

  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);

  const [
    updateCompanyServicesModalVisible,
    setUpdateCompanyServicesModalVisible,
  ] = useState(false);
  const [
    createCompanyUserModalVisible,
    setCreateCompanyUserModalVisible,
  ] = useState(false);

  const { loading, company } = useSelector(
    ({ firestore: { status, data } }) => ({
      loading: status.requesting[`companies/${companyId}`],
      company: (data.companies && data.companies[companyId]) || {},
    }),
  );

  useEffect(() => {
    if (!loading && !isEmpty(company)) {
      const getServices = async () => {
        try {
          setLoadingServices(true);

          const companyServices = company?.services || [];

          const list = await Promise.all(
            companyServices.map(async (service) =>
              downloadJson(firebase, `services/${service}`),
            ),
          );

          setServices(list);
        } catch (err) {
          message.error('Falha ao carregar os serviços');
        } finally {
          setLoadingServices(false);
        }
      };

      getServices();
    }
  }, [loading, company, firebase]);

  useEffect(() => {
    const getCompanyUsers = async () => {
      try {
        setLoadingUsers(true);

        const companyRef = await firestore
          .collection('companies')
          .doc(companyId);

        const usersRef = await firestore
          .collection('users')
          .where('company', '==', companyRef)
          .get();

        let usersList = [];

        usersRef.forEach((doc) => {
          usersList = [...usersList, { id: doc.id, ...doc.data() }];
        });

        setUsers(usersList);
      } catch (err) {
        message.error('Falha ao carregar os usuários.');
      } finally {
        setLoadingUsers(false);
      }
    };

    if (!createCompanyUserModalVisible && !loadingRemoveUser) {
      getCompanyUsers();
    }
  }, [firestore, companyId, createCompanyUserModalVisible, loadingRemoveUser]);

  const handleUpdateCompanyServicesModalVisible = () => {
    setUpdateCompanyServicesModalVisible(!updateCompanyServicesModalVisible);
  };

  const handleCreateCompanyUserModalVisible = () => {
    setCreateCompanyUserModalVisible(!createCompanyUserModalVisible);
  };

  const handleRemoveCompanyUserClick = (user) => {
    Modal.confirm({
      title: 'Remoção de Usuário da Empresa',
      icon: <ExclamationCircleOutlined />,
      content: `Deseja realmente remover o usuário ${user?.name}?`,
      okType: 'danger',
      async onOk() {
        try {
          setLoadingRemoveUser(true);

          await firestore
            .collection('users')
            .doc(user.id)
            .delete();

          message.success('Usuário removido com sucesso!');
        } catch (err) {
          message.error('Falha ao remover o usuário.');
        } finally {
          setLoadingRemoveUser(false);
        }
      },
    });
  };

  const serviceColumns = [
    {
      title: 'Nome',
      dataIndex: 'Name',
    },
    {
      title: 'Descrição',
      dataIndex: 'Description',
    },
    {
      title: 'Id',
      dataIndex: 'Id',
    }
  ];

  const userColumns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Equipe',
      dataIndex: 'team',
      render: (value) => <Tag>{value || '-'}</Tag>,
    },
    {
      title: 'Ações',
      render: (row) => (
        <Button danger onClick={() => handleRemoveCompanyUserClick(row)}>
          Remover
        </Button>
      ),
    },
  ];

  return (
    <>
      <PageSection loading={loading} className="no-padding">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '24px 24px 12px 24px',
          }}
        >
          <Typography.Title level={4}>Serviços</Typography.Title>
          <Button onClick={handleUpdateCompanyServicesModalVisible}>
            Alterar Serviços
          </Button>
        </div>
        <Table
          dataSource={services}
          rowKey={(row) => row.Id}
          columns={serviceColumns}
          loading={loadingServices}
          pagination={false}
          size="small"
          style={{ paddingBottom: '24px' }}
        />
      </PageSection>
      <PageSection loading={loading} className="no-padding">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '24px 24px 12px 24px',
          }}
        >
          <Typography.Title level={4}>Usuários</Typography.Title>
          <div>
            <Button onClick={handleCreateCompanyUserModalVisible}>
              Novo Usuário
            </Button>
          </div>
        </div>
        <Table
          dataSource={users}
          rowKey={(row) => row.id}
          columns={userColumns}
          loading={loadingUsers}
          pagination={false}
          size="small"
          style={{ paddingBottom: '24px' }}
        />
      </PageSection>
      <UpdateCompanyServicesModal
        visible={updateCompanyServicesModalVisible}
        handleVisible={handleUpdateCompanyServicesModalVisible}
      />
      <CreateCompanyUserModal
        visible={createCompanyUserModalVisible}
        handleVisible={handleCreateCompanyUserModalVisible}
      />
    </>
  );
};

CompanyDetailsConfigPage.propTypes = {};

export default CompanyDetailsConfigPage;
