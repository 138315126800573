import React from 'react';
import PropTypes from 'prop-types';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ConfigProvider } from 'antd';
import firebase from 'firebase/app';
import locale from 'antd/es/locale/pt_BR';

import firebaseConfig from './state/firebase/firebaseConfig';
import configureStore from './state/store/configureStore';

import 'firebase/auth';
import 'firebase/firestore';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const Redirecting = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <div>Redirecting...</div>;
  return children;
};

const Root = ({ children, initialState = {} }) => {
  const store = configureStore(initialState);
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <Redirecting>{children}</Redirecting>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </ConfigProvider>
    </Provider>
  );
};

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
  ]),
  initialState: PropTypes.object,
};

export default Root;
