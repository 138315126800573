const firebaseConfigV1 = {
    apiKey: 'AIzaSyC-6wTVc9VJwtd7YT4v08MSawbKitBb1IE',
    authDomain: 'co-opera.firebaseapp.com',
    databaseURL: 'https://co-opera.firebaseio.com',
    projectId: 'co-opera',
    storageBucket: 'co-opera.appspot.com',
    messagingSenderId: '415972900610',
    appId: '1:415972900610:web:7c8462a271e10de2',
  };
  
  export default firebaseConfigV1;
  