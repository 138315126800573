import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import {
  Button,
  Row,
  Col,
  Space,
  Typography,
  Form,
  Input,
  notification,
} from 'antd';

import PageSection from '@/components/_common/PageSection';

const HomePage = () => {
  const [form] = Form.useForm();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  const [loginLoading, setLoginLoading] = useState(false);

  const handleLoginClick = async () => {
    try {
      setLoginLoading(true);

      const values = await form.validateFields();

      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      const profileRef = await firestore
        .collection('users')
        .doc(response.user.uid)
        .get();

      const { role } = profileRef.data();

      if (role !== 'admin') {
        await firebase.auth().signOut();

        notification.error({
          message: 'Você não tem permissão para acessar o sistema',
          description: 'Tente com outro e-mail e senha',
        });

        setLoginLoading(false);

        return;
      }
      setLoginLoading(false);

      history.push('/app/companies');
    } catch (err) {
      notification.error({
        message: 'Você não tem permissão para acessar o sistema',
        description: 'Tente com outro e-mail e senha',
      });

      setLoginLoading(false);
    }
  };

  return (
    <Row justify="center">
      <Col md={24} lg={12} xl={8}>
        <PageSection style={{ padding: '48px' }}>
          <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <Typography>
              <Typography.Title
                style={{
                  textAlign: 'center',
                  wordWrap: 'break-word',
                  marginBottom: '12px',
                }}
              >
                Módulo Administrativo
              </Typography.Title>
            </Typography>
            <Form form={form}>
              <Form.Item
                name="email"
                label="E-Mail"
                rules={[
                  {
                    required: true,
                    message: 'Favor inserir o e-mail do usuário.',
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Senha"
                rules={[
                  {
                    required: true,
                    message: 'Favor inserir a senha do usuário.',
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              <Button
                block
                htmlType="submit"
                type="primary"
                loading={loginLoading}
                onClick={handleLoginClick}
                style={{ marginTop: '24px' }}
              >
                Login
              </Button>
            </Form>
          </Space>
        </PageSection>
      </Col>
    </Row>
  );
};

export default HomePage;
