import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Button, Modal, Descriptions, message, Skeleton, Tabs } from 'antd';
import { ExclamationCircleOutlined, SettingOutlined } from '@ant-design/icons';

import PrivatePageHeader from '@/components/_common/PrivatePageHeader';
import UpdateCompanyModal from '@/components/Companies/UpdateCompanyModal';
import { CustomRoute } from '@/App';

const CompanyDetailsPage = ({ route }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const firestore = useFirestore();

  useEffect(() => {
    if (match.isExact) {
      history.push(`/app/companies/${match.params.companyId}/config`);
    }
  }, [match, history]);

  useFirestoreConnect([
    {
      collection: 'companies',
      doc: match.params.companyId,
    },
  ]);

  const { loading, company } = useSelector(
    ({ firestore: { status, data } }) => ({
      loading: status.requesting[`companies/${match.params.companyId}`],
      company: (data.companies && data.companies[match.params.companyId]) || {},
    }),
  );

  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const handleUpdateModalVisible = () => {
    setUpdateModalVisible(!updateModalVisible);
  };

  const handleRemoveClick = () => {
    Modal.confirm({
      title: 'Remoção de Empresa',
      icon: <ExclamationCircleOutlined />,
      content: `Deseja realmente remover a empresa ${company?.name}?`,
      okType: 'danger',
      async onOk() {
        try {
          await firestore
            .collection('companies')
            .doc(match.params.companyId)
            .delete();

          message.success('Empresa removida com sucesso!');

          history.push('/app/companies');
        } catch (err) {
          message.error('Falha ao remover a empresa.');
        }
      },
    });
  };

  const handleTabChange = (key) => {
    history.push(`/app/companies/${match.params.companyId}/${key}`);
  };

  return (
    <div>
      <PrivatePageHeader
        route={route}
        title={company?.name}
        breadcrumbIds={{
          companyId: { text: company?.name, link: match.params.companyId },
        }}
        loading={loading}
        extra={[
          <Button key="0" onClick={handleUpdateModalVisible}>
            Editar
          </Button>,
          <Button key="1" danger onClick={handleRemoveClick}>
            Remover
          </Button>,
        ]}
        onBack={() => history.push('/app/companies')}
        footer={
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <Tabs.TabPane
              tab={
                <span>
                  <SettingOutlined />
                  Configurações
                </span>
              }
              key="config"
            />
          </Tabs>
        }
      >
        <Skeleton active loading={loading}>
          <Descriptions>
            <Descriptions.Item label="Nome">{company?.name}</Descriptions.Item>
            <Descriptions.Item label="Prefixo">{company?.prefix}</Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </PrivatePageHeader>

      {route.routes.map((item) => (
        <CustomRoute {...item} />
      ))}

      <UpdateCompanyModal
        visible={updateModalVisible}
        handleVisible={handleUpdateModalVisible}
      />
    </div>
  );
};

CompanyDetailsPage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default CompanyDetailsPage;
