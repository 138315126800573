const firebaseConfig = {
  apiKey: 'AIzaSyCm_1rz8dmjAsmVTMYGZhdKoXCSlvFTNPY',
  authDomain: 'co-opera-v2.firebaseapp.com',
  databaseURL: 'https://co-opera-v2.firebaseio.com',
  projectId: 'co-opera-v2',
  storageBucket: 'co-opera-v2.appspot.com',
  messagingSenderId: '118467137262',
  appId: '1:118467137262:web:3a24b08cc42e904c152dff',
};

export default firebaseConfig;
