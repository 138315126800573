import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { Layout } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';

// import { getRoutesByGroup } from '@/utils/route';
// import { useWindowSize } from '@/hooks';
// import routes from '@/routes';

// import userManager from '@/state/userManager';

import logo from '../../assets/images/Logo.svg';

import './style.less';

const { Header, Content, Footer } = Layout;

// const Logo = (props) => <div {...props} />; // eslint-disable-line

const PublicLayout = (props) => {
  // const { isMobile } = useWindowSize();
  // const { user, isLoadingUser } = useSelector(({ oidc }) => oidc);

  const {
    path,
    children,
    // location: { pathname },
  } = props;

  // const menuRoutes = getRoutesByGroup(routes, 'public');

  // const menuKeys = menuRoutes.map((route) => route.path);
  // const selectedMenuKeys = getSelectedMenuKeys(menuKeys, pathname);

  // const handleLoginClick = () => {
  //   userManager.signinRedirect();
  // };

  // const menuItems = menuRoutes.map((route) => {
  //   const className = route.key === 'login' && !isMobile ? 'menu-login' : '';
  //   const link = route.key === 'home' ? '/' : route.path;
  //   const logged = user !== null && !isLoadingUser;

  //   if (route.key === 'login') {
  //     return logged ? (
  //       <Menu.Item key="/app/profile" className={className}>
  //         <Link to="/app/profile">Dashboard</Link>
  //       </Menu.Item>
  //     ) : (
  //       <Menu.Item key="login" className={className} onClick={handleLoginClick}>
  //         Login
  //       </Menu.Item>
  //     );
  //   }

  //   return (
  //     <Menu.Item key={route.path} className={className}>
  //       <Link to={link}>{route.name}</Link>
  //     </Menu.Item>
  //   );
  // });

  return (
    <Route
      path={path}
      render={() => (
        <Layout className="page-layout">
          <Header className="page-header">
            {/* <Logo className="logo" /> */}
            <img src={logo} alt="logo" width="200"/>
            {/* <Menu
              theme="dark"
              mode="horizontal"
              className="menu"
              defaultSelectedKeys={
                selectedMenuKeys.length ? selectedMenuKeys : ['/home']
              }
            >
              {isMobile ? (
                <Menu.SubMenu
                  className="mobile-submenu"
                  title={<MenuOutlined />}
                >
                  {menuItems}
                </Menu.SubMenu>
              ) : (
                menuItems
              )}
            </Menu> */}
          </Header>
          <Content className="page-content">{children}</Content>
          <Footer className="page-footer">
            <p>co.urban ©2020</p>
            <p>
              <a href="https://istic.senai.io/">Desenvolvido pelo ISTIC</a>
            </p>
          </Footer>
        </Layout>
      )}
    />
  );
};

PublicLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  path: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  // location: PropTypes.object,
};

export default PublicLayout;
