import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import locale from 'moment/locale/pt-br';

import App from './App';

moment.updateLocale('pt-br', locale);

ReactDOM.render(<App />, document.getElementById('root'));
