import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Layout, Spin } from 'antd';

import { useWindowSize } from '@/hooks';

import PrivateLayoutSiderMenu from '@/components/PrivateLayout/SiderMenu';
import PrivateLayoutHeader from '@/components/PrivateLayout/Header';

import './style.less';

const { Header, Content, Footer } = Layout;

const PrivateLayout = ({ path, exact, children, routes }) => {
  const { isMobile } = useWindowSize();
  const [collapsed, setCollapsed] = useState(isMobile);
  const [logoutRedirecting, setLogoutRedirecting] = useState(false);
  const auth = useSelector((state) => state.firebase.auth);

  const handleMenuCollapse = (c) => setCollapsed(c);

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <Layout style={{ minHeight: '100vh' }}>
            <PrivateLayoutSiderMenu
              collapsed={collapsed}
              onCollapse={handleMenuCollapse}
              routes={routes}
            />
            <Layout>
              <Header className="header-wrapper">
                <PrivateLayoutHeader
                  collapsed={collapsed}
                  onCollapse={handleMenuCollapse}
                  logoutRedirecting={logoutRedirecting}
                  setLogoutRedirecting={setLogoutRedirecting}
                />
              </Header>
              <Spin spinning={false}>
                <Content className="content-wrapper">{children}</Content>
              </Spin>
              <Footer className="footer-wrapper">ISTIC ©2020</Footer>
            </Layout>
          </Layout>
        ) : (
          <Redirect to={{ path: '/', state: { from: location } }} />
        )
      }
    />
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  exact: PropTypes.bool,
};

export default PrivateLayout;
