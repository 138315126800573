import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { Modal, Form, message } from 'antd';

import CompanyForm from '../CompanyForm';

const CreateCompanyModal = ({ visible, handleVisible }) => {
  const [form] = Form.useForm();
  const firestore = useFirestore();

  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      await firestore.collection('companies').add(values);

      message.success('Empresa cadastrada com sucesso!');
      form.resetFields();
      handleVisible();
    } catch (err) {
      message.error('Falha ao cadastrar a empresa.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();

    handleVisible();
  };

  return (
    <Modal
      title="Nova Empresa"
      visible={visible}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <CompanyForm form={form} modalVisible={visible} />
    </Modal>
  );
};

CreateCompanyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleVisible: PropTypes.func.isRequired,
};

export default CreateCompanyModal;
