import React from 'react';
import { Row, Typography, Col, Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import userManager from '@/state/userManager';

const ForbiddenPage = () => {
  const { user } = useSelector(({ oidc }) => oidc);
  const history = useHistory();

  const handleLoginClick = () => {
    userManager.removeUser();
    history.push('/');
  };

  const handleHomeClick = () => {
    if (user) {
      const { company_id } = user.profile; // eslint-disable-line
      const url = `/app/companies/${company_id}/home`; // eslint-disable-line

      history.push(url);
    }
  };

  return (
    <Row
      style={{ height: '100vh', width: '100%' }}
      justify="center"
      align="middle"
      gutter={24}
    >
      <Col style={{ borderLeft: '1px solid #F0F0F0', paddingLeft: '48px' }}>
        <Row>
          <Typography.Title>403</Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>
            Desculpe, mas aparentemente você não tem permissão para ver a página
            solicitada.
          </Typography.Paragraph>
        </Row>
        <Row>
          <Space>
            <Button type="primary" onClick={handleHomeClick}>
              Voltar para Home
            </Button>
            <Button onClick={handleLoginClick}>Voltar para Login</Button>
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export default ForbiddenPage;
