import React  from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Switch  } from 'antd';

const UserForm = ({ form, showIsAdmin, showTeam, checkPortalAccess, changePortalAccess }) => {

  

  const onCheckboxChange = (e) => {
    changePortalAccess();
  };


  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };


  return (
    <Form {...formLayout} form={form}>      
      <Form.Item
        name="name"
        label="Nome"
        rules={[
          { required: true, message: 'Favor inserir o nome do Usuário.' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="companyUserId"
        label="Id Usuário"
        rules={[
          { required: true, message: 'Favor inserir o Id do Usuário.' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="access_portal" label="Acesso Portal" valuePropName="checked" >
        <Switch onChange={() => {onCheckboxChange()}}/>
      </Form.Item>

      {checkPortalAccess ? 
      <Form.Item
        name="email"
        label="E-Mail Recuperação"
        rules={[
          { required: true, message: 'Favor inserir o e-mail Válido para recuperação de senha.' },
        ]}
      >
        <Input />
      </Form.Item>
      : null}
      

      {showTeam ? (
        <Form.Item
          name="team"
          label="Equipe"
          rules={[
            { required: true, message: 'Favor inserir a equipe do Usuário.' },
          ]}
        >
          <Input />
        </Form.Item>
      ) : null}

      {showIsAdmin ? (
        <Form.Item label="Perfil" name="isAdmin" valuePropName="checked">
          <Switch
            checkedChildren="Administrador"
            unCheckedChildren="Empresa"
            style={{ width: '126px' }}
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="password"
        label="Senha"
        rules={[
          {
            required: true,
            message: 'Favor inserir a senha do usuário.',
          },
          {
            min: 6,
            message: 'A senha deve conter ao menos 6 caracteres.',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirmar Senha"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Favor confirmar a sua senha.',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('As duas senhas não coincidem.');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,

  showIsAdmin: PropTypes.bool,
  showTeam: PropTypes.bool,
};

UserForm.defaultProps = {
  showIsAdmin: true,
  showTeam: false
};

export default UserForm;
