import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { PageHeader } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { getRouteBreadcrumb } from '@/utils/route';

import './style.less';

const PrivatePageHeader = ({
  route,
  loading,
  breadcrumbIds,
  overrideLinks,
  ...rest
}) => {
  const breadcrumb = getRouteBreadcrumb(route).map((bc, i) => ({
    ...bc,
    index: i,
  }));

  // console.log(overrideLinks, breadcrumb);

  const getBreadcrumbNameFromIds = (id) => {
    const key = id.slice(1);

    return breadcrumbIds[key].text;
  };

  const getBreadcrumbLinkFromIds = (path, id) => {
    const key = id.slice(1);

    return path.replace(id, breadcrumbIds[key].link);
  };

  const itemRender = (item, _, routes) => {
    const last = routes.indexOf(item) === routes.length - 1;
    let hasLink = !last;

    const isParam = item.breadcrumbName.indexOf(':') === 0;

    const breadcrumbName = isParam
      ? getBreadcrumbNameFromIds(item.breadcrumbName)
      : item.breadcrumbName;

    let breadcrumbLink = isParam
      ? getBreadcrumbLinkFromIds(item.path, item.breadcrumbName)
      : item.path;

    if (!isEmpty(overrideLinks)) {
      const newLink = overrideLinks[item.index];
      if (newLink) breadcrumbLink = newLink;
      if (newLink === null) hasLink = false;
    }

    return !hasLink ? (
      <span>{loading && isParam ? <LoadingOutlined /> : breadcrumbName}</span>
    ) : (
      <Link to={breadcrumbLink}>
        {loading && isParam ? <LoadingOutlined /> : breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      className="private-page-header"
      breadcrumb={{ routes: breadcrumb, itemRender }}
      {...rest}
    />
  );
};

PrivatePageHeader.propTypes = {
  route: PropTypes.object.isRequired,

  loading: PropTypes.bool,
  breadcrumbIds: PropTypes.object,
  overrideLinks: PropTypes.object,
};

export default PrivatePageHeader;
