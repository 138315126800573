import React from 'react';
import { Row, Col, Typography, Space, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const UnauthorizedPage = () => {
  const history = useHistory();

  const handleLoginClick = () => {
    history.push('/');
  };

  return (
    <Row
      style={{ height: '100vh', width: '100%' }}
      justify="center"
      align="middle"
      gutter={24}
    >
      <Col style={{ borderLeft: '1px solid #F0F0F0', paddingLeft: '48px' }}>
        <Row>
          <Typography.Title>401</Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>
            Desculpe, mas aparentemente você não tem credenciais de acesso
            válidas para ver esta página.
          </Typography.Paragraph>
        </Row>
        <Row>
          <Space>
            <Button onClick={handleLoginClick}>Voltar para Login</Button>
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export default UnauthorizedPage;
