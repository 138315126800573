import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Modal, Table } from 'antd';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { downloadJson } from '@/utils/firebase';

import 'firebase/storage';
import { useRouteMatch } from 'react-router-dom';

const UpdateCompanyServicesModal = ({ visible, handleVisible }) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { companyId } = useRouteMatch().params;

  const company = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[companyId],
  );

  const [loadingServices, setLoadingServices] = useState(false);
  const [updatingCompanyServices, setUpdatingCompanyServices] = useState(false);

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (visible) {
      const fetchServices = async () => {
        try {
          setLoadingServices(true);

          const storage = firebase.storage();
          const servicesRef = storage.ref('services');

          const { items = [] } = await servicesRef.list({
            maxResults: 999,
          });

          const servicePaths = items.map((item) => item.fullPath);

          const data = await Promise.all(
            servicePaths.map(async (service) =>
              downloadJson(firebase, service),
            ),
          );
          const list = data.map((item) => ({
            ...item,
            fileName: `${item.Id}.json`,
          }));

          setServices(list);
        } catch (err) {
          console.log(err)
          message.error('Falha ao carregar os serviços.');
        } finally {
          setLoadingServices(false);
        }
      };

      fetchServices();
    }
  }, [visible, firebase]);

  useEffect(() => {
    if (!isEmpty(company)) {
      const list = company.services || [];

      setSelectedServices(list);
    }
  }, [company]);

  const handleOk = async () => {
    try {
      setUpdatingCompanyServices(true);

      await firestore
        .collection('companies')
        .doc(companyId)
        .update({ services: selectedServices });

      message.success('Serviços atualizados com sucesso.');

      handleVisible();
    } catch (err) {
      message.error('Falha ao atualizar os serviços da empresa.');
    } finally {
      setUpdatingCompanyServices(false);
    }
  };

  const handleCancel = () => {
    handleVisible();
  };

  const serviceColumns = [
    {
      title: 'Nome',
      dataIndex: 'Name',
    },
    {
      title: 'Descrição',
      dataIndex: 'Description',
    },
    {
      title: 'Id',
      dataIndex: 'Id',
    }
  ];

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedServices,
    onChange: (selectedRowKeys) => {
      setSelectedServices(selectedRowKeys);
    },
  };

  return (
    <Modal
      title="Alterar Serviços da Empresa"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={712}
      confirmLoading={loadingServices || updatingCompanyServices}
      bodyStyle={{ padding: '24px 0' }}
    >
      <Table
        dataSource={services}
        rowKey={(row) => row.fileName}
        rowSelection={rowSelection}
        columns={serviceColumns}
        loading={loadingServices}
        pagination={false}
        size="small"
      />
    </Modal>
  );
};

UpdateCompanyServicesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleVisible: PropTypes.func.isRequired,
};

export default UpdateCompanyServicesModal;
