import React from 'react';

import { TeamOutlined, ShopOutlined } from '@ant-design/icons';

import formatRoutes from '@/utils/route/formatRoutes';

import HomePage from '@/pages/HomePage';
import LoginCallbackPage from '@/pages/LoginCallbackPage';
import UsersPage from '@/pages/UsersPage';

import ForbiddenPage from '@/pages/ForbiddenPage';
import UnauthorizedPage from '@/pages/UnauthorizedPage';
import CompaniesPage from '@/pages/CompaniesPage';
import CompanyDetailsPage from '@/pages/CompanyDetailsPage';
import CompanyDetailsConfigPage from '@/pages/CompanyDetailsConfigPage';
// import CompanyDetailsOperationsPage from '@/pages/CompanyDetailsOperationsPage';

const routes = [
  {
    key: 'home',
    groupKey: 'public',
    exact: true,
    component: HomePage,
    name: 'Home',
  },
  {
    key: 'app',
    routes: [
      {
        key: 'companies',
        exact: true,
        component: CompaniesPage,
        name: 'Empresas',
        menu: {
          icon: <ShopOutlined />,
        },
        routes: [
          {
            key: ':companyId',
            component: CompanyDetailsPage,
            name: ':companyId',
            routes: [
              // {
              //   key: 'operations',
              //   component: CompanyDetailsOperationsPage,
              //   name: 'Execuções',
              // },
              {
                key: 'config',
                component: CompanyDetailsConfigPage,
                name: 'Configurações',
              },
            ],
          },
        ],
      },
      {
        key: 'users',
        exact: true,
        component: UsersPage,
        name: 'Usuários',
        menu: {
          icon: <TeamOutlined />,
        },
      },
    ],
  },
  {
    key: 'callback',
    component: LoginCallbackPage,
    name: 'LoginCallback',
  },
  {
    key: '403',
    exact: true,
    component: ForbiddenPage,
    name: 'Forbidden',
  },
  {
    key: '401',
    exact: true,
    component: UnauthorizedPage,
    name: 'Forbidden',
  },
];

export default formatRoutes(routes);
