import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const CompanyForm = ({ form }) => {
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <Form {...formLayout} form={form}>
      <Form.Item
        name="name"
        label="Nome"
        rules={[
          { required: true, message: 'Favor inserir o nome da Empresa.' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="prefix"
        label="Prefixo"
        rules={[
          { required: true, message: 'Favor inserir o prefixo de 3 letras da Empresa.' },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

CompanyForm.propTypes = {
  form: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default CompanyForm;
