import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { Modal, Form, message } from 'antd';
import firebase from 'firebase/app';

import 'firebase/auth';

import firebaseConfig from '@/state/firebase/firebaseConfig';

import UserForm from '../UserForm';

const secondInstance = firebase.initializeApp(
  firebaseConfig,
  'Create User Instance',
);

const CreateUserModal = ({ visible, handleVisible }) => {
  const [form] = Form.useForm();
  const firestore = useFirestore();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const profile = {
        email: values.email,
        name: values.name,
        role: 'admin',
      };

      const createAuthUserResult = await secondInstance
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);

      await secondInstance.auth().signOut();

      await firestore
        .collection('users')
        .doc(createAuthUserResult.user.uid)
        .set(profile);

      message.success('Usuário cadastrado com sucesso!');
      form.resetFields();
      handleVisible();
    } catch (err) {
      message.error('Falha ao cadastrar o usuário.');
    }
  };

  const handleCancel = () => {
    form.resetFields();

    handleVisible();
  };

  return (
    <Modal
      title="Novo Usuário"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <UserForm form={form} modalVisible={visible} showIsAdmin={false} />
    </Modal>
  );
};

CreateUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleVisible: PropTypes.func.isRequired,
};

export default CreateUserModal;
