import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';

import PageSection from '@/components/_common/PageSection';
import PrivatePageHeader from '@/components/_common/PrivatePageHeader';
import CreateCompanyModal from '@/components/Companies/CreateCompanyModal';

const CompaniesPage = ({ route }) => {
  useFirestoreConnect({
    collection: `companies`,
    storeAs: 'companies',
  });

  const { companies = {} } = useSelector((state) => state.firestore.data);

  const [createCompanyModalVisible, setCreateCompanyModalVisible] = useState(
    false,
  );

  const companyDataSource = Object.keys(companies).map((key) => ({
    ...companies[key],
    id: key
  }));

  const handleCreateCompanyModalVisible = () => {
    setCreateCompanyModalVisible(!createCompanyModalVisible);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Prefixo',
      dataIndex: 'prefix',
    },
    {
      title: 'Ações',
      render: (row) => (
        <div>
          <Link to={`/app/companies/${row.id}`}>
            <Button>Ver Detalhes</Button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <PrivatePageHeader
        route={route}
        title="Empresas"
        onBack={() => window.history.back()}
        extra={[
          <Button
            key="new"
            type="primary"
            onClick={handleCreateCompanyModalVisible}
          >
            Nova Empresa
          </Button>,
        ]}
      />
      <PageSection className="table-page-section">
        <Table
          size="middle"
          rowKey={(item) => item.id}
          columns={columns}
          dataSource={companyDataSource}
        />
      </PageSection>
      <CreateCompanyModal
        visible={createCompanyModalVisible}
        handleVisible={handleCreateCompanyModalVisible}
      />
    </>
  );
};

CompaniesPage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default CompaniesPage;
