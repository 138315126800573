import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import routes from './routes';

import Root from './Root';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import { getRoute, getRoutesByGroup } from './utils/route';

import './assets/styles/global.less';

export const CustomRoute = (props) => {
  const { component: Component, path, exact } = props;

  return (
    <Route
      key={path}
      path={path}
      exact={exact}
      render={(routeProps) => <Component {...routeProps} route={props} />}
    />
  );
};

CustomRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  exact: PropTypes.bool,
  route: PropTypes.string,
};

const App = () => {
  const app = getRoute(routes, 'app');
  const companies = getRoute(routes, 'companies');

  const publicRoutes = getRoutesByGroup(routes, 'public');
  const loginCallback = getRoute(routes, 'callback');
  const forbidden = getRoute(routes, '403');
  const unauthorized = getRoute(routes, '401');

  return (
    <Root>
      <Switch>
        <PrivateLayout path="/app" routes={app.routes}>
          {app.routes.map((route) => (
            <CustomRoute {...route} />
          ))}
          {companies.routes.map((route) => (
            <CustomRoute {...route} />
          ))}
        </PrivateLayout>
        <Route {...loginCallback} />
        <Route {...forbidden} />
        <Route {...unauthorized} />
        <PublicLayout path="/">
          {publicRoutes.map((route) => (
            <Route {...route} path={route.key === 'home' ? '/' : route.path} />
          ))}
        </PublicLayout>
      </Switch>
    </Root>
  );
};

export default App;
