import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal, Form, message } from 'antd';
import { useFirestore } from 'react-redux-firebase';

import CompanyForm from '../CompanyForm';

const UpdateCompanyModal = ({ visible, handleVisible }) => {
  const [form] = Form.useForm();
  const match = useRouteMatch();
  const firestore = useFirestore();

  const company = useSelector(
    ({ firestore: { data } }) =>
      (data.companies && data.companies[match.params.companyId]) || {},
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && !isEmpty(company)) {
      form.setFieldsValue(company);
    }
  }, [visible, form, company]);

  const handleOk = async () => {
    try {
      setLoading(true);

      const { companyId } = match.params;
      const values = await form.validateFields();

      await firestore
        .collection('companies')
        .doc(companyId)
        .set(values);

      message.success('Empresa editada com sucesso!');
      form.resetFields();
      handleVisible();
    } catch (err) {
      message.error('Falha ao editar a empresa.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();

    handleVisible();
  };

  return (
    <Modal
      title="Atualizar Empresa"
      visible={visible}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
      width={712}
    >
      <CompanyForm form={form} modalVisible={visible} />
    </Modal>
  );
};

UpdateCompanyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleVisible: PropTypes.func.isRequired,
};

export default UpdateCompanyModal;
