import 'firebase/storage';

const downloadJson = async (firebase, url) => {
  const storage = firebase.storage();
  const fbUrl = await storage.ref(url).getDownloadURL();

  const response = await fetch(fbUrl);
  const json = await response.json();

  return json;
};

export default downloadJson;
